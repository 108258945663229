import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Privacy" />

    <div>
      <h1 className="text-4xl text-black mb-4">
        Privacy <span className="text-blue-600">Policy</span>
      </h1>

      <p>
        I am registered with the Information Commissioner’s Register of Data Controllers registration number ZA365779, (ICO) and as such I am required to be transparent about how and why personal data is gathered, recorded, stored, how and when it is deleted.
      </p>
      <h2>Personal information</h2>
      <p>
        When you contact me via email, mobile/text or telephone your contact details will be stored electronically, either on a PC or mobile phone. These will be used in order to make contact with you to set up an initial meeting. If you choose to continue working with me then the contact details will be kept in order for me to be able to contact you as we work together if needed. This contact information will then be deleted a month after we have finished working together.
      </p>
      <p>
        During your initial consultation I will ask you to read and sign a counselling contract, I will also ask you to complete a consent form containing your contact information along with your details and doctors telephone number.
      </p>
      <p>
        Storing your personal information
      </p>
      <p>
        All personal information and session notes are stored electronically ensuring that all data is password protected and retained for the legal requirement of 7 years,(more in the case of under 18s) after which time will be deleted.
      </p>


      <h2>Confidentiality</h2>
      <p>
        All sessions will be conducted in confidence, the date, session number and brief notes will be documented.
      </p>
      <p>
        Confidentiality, in accordance with British Association for Counselling and Psychotherapy and the General Data Protection Regulation 2018 (GDPR), will be maintained and applied to all records with the exception of the following:
      </p>
      <p>
        When you have given your permission to share information. 
      </p>
      <p>
        When compelled to give evidence by a court of law.
      </p>
      <p>
        Safeguarding adults (adult protection) Safeguarding children (child protection).
      </p>
      <p>
        Offences involving children under the age of 18.
      </p>
      <p>
        In cases of terrorism, fraud or money laundering .
      </p>
      <p>
        Website
      </p>
      <p>
        There is no contact form on this website as I feel that I cannot guarantee that this method of communication is totally secure. Therefore I prefer contact via email… enquiries@margaretheap.co.uk or by telephone or text… 07544259118. This communication will be deleted a month after we finish working together.
      </p>
      <p>
        For security purposes there are various cameras around the outside of the property. These are wiped daily on some cameras and are overwritten periodically on the rest. No data is retained.
      </p>
      <p>
        By visiting www.margaretheap.co.uk website you accept and consent to the terms described in this privacy policy.
      </p>

      <h2>Cookies</h2>
      <p>
        A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
      </p>
      <p>
        Overall, cookies help me provide you with a better website, by enabling me to monitor which pages you find useful and which you do not. A cookie in no way gives me access to your computer or any information about you.
      </p>
      <p>
        You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of some websites.
      </p>

      <h2>Google Analytics</h2>
      <p>
        Each time you visit a website Google analytics automatically tracks information such as (but not limited to) your geographical location, IP address, browser type, browser version, operating system, referral source, page views, length of visit, the times and dates you visit the site, navigation paths, whether you are a new visitor or a returning visitor.
      </p>
      <p>
        When you visit www.margaretheap.co.uk the information collected by Google analytics may be used by me to customise the website according to your interests and help improve my service.
      </p>


      <h2>Controlling your personal information</h2>
      <p>
        I will not sell, distribute or lease your personal information to third parties unless I have your permission or is required by law to do so.
      </p>
      <p>
        You may request details of personal information which I hold about you under the General Data Protection Regulation 2018 (GDPR). If you would like a copy of any stored personal information, please write to Margaret Heap Counselling and Psychotherapy, 458 Burnley Road, Accrington, Lancashire, BB5 6JU.
      </p>


      <h2>Amendments and updates</h2>
      <p>
        I may amend and/or update this privacy policy from time to time without notice.
      </p>
      <p>
        Please check this page from time to time to ensure that you are happy with any changes
      </p>
    </div>
  </Layout>
)

export default IndexPage
